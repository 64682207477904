exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-genres-js": () => import("./../../../src/pages/genres.js" /* webpackChunkName: "component---src-pages-genres-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-essay-js": () => import("./../../../src/templates/essay.js" /* webpackChunkName: "component---src-templates-essay-js" */),
  "component---src-templates-genre-js": () => import("./../../../src/templates/genre.js" /* webpackChunkName: "component---src-templates-genre-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-marble-item-js": () => import("./../../../src/templates/marble-item.js" /* webpackChunkName: "component---src-templates-marble-item-js" */)
}

